var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        { attrs: { app: "", permanent: "" } },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.items, function(item) {
              return _c(
                "v-list-tile",
                { key: item.title, attrs: { to: item.to, exact: "" } },
                [
                  _c(
                    "v-list-tile-action",
                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                    1
                  ),
                  _c(
                    "v-list-tile-content",
                    [_c("v-list-tile-title", [_vm._v(_vm._s(item.title))])],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("v-container", { attrs: { fluid: "" } }, [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }